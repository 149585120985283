import React from "react";
import PropTypes from "prop-types";

const OrderedList = (props) => {
    return (
        <ol className={"list-decimal list-outside ml-5 " + (props.className ?? "")}>
            {props.children}
        </ol>
    )
}

OrderedList.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
}

export default OrderedList;
