import React from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import SEO from "../components/SEO";
import Section from "../components/Section";
import SectionRow from "../components/SectionRow";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import SVGCurve from "../components/SVGCurve";
import OrderedList from "../components/OrderedList";
import DivWithText from "../components/DivWithText";
import ExternalLink from "../components/ExternalLink";
import InternalLink from "../components/InternalLink";
// import InternalLink from "../components/InternalLink";

function AboutPage() {
    return (
        <LayoutGeneral>
            <SEO
                keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Psychiatry`, `Therapy`, `Psychotherapy`, `Telepsychiatry`, `Counseling`,
                `Appointments`,`Medication Management`]}
                title="Appointments"
                description="Agile Psychiatry offers medication management, counseling, and combined appointments under
                the care of Brian Nahas, MD"
            />

            {/*<Section shade="mid">*/}
            {/*    <SectionRow>*/}
            {/*        <TextDiv>*/}
            {/*            <h2 className="text-3xl text-center mb-2">Appointments coming soon! (Updated 1/4/2021)</h2>*/}
            {/*            <TextParagraph className="mb-8">*/}
            {/*                Agile Psychiatry is currently in the process of launching and will be open for appointment*/}
            {/*                scheduling in the near future. This page will be updated with an online form to request*/}
            {/*                an appointment after launch. Please check back soon if you would like to schedule*/}
            {/*                an appointment.*/}
            {/*            </TextParagraph>*/}
            {/*        </TextDiv>*/}
            {/*    </SectionRow>*/}
            {/*</Section>*/}
            {/*<SVGCurve direction="right-slope" transition="mid-light"/>*/}
            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <h2 className="text-3xl text-center mb-2">Appointment Request Process</h2>
                        <TextParagraph className="mb-6 font-bold">
                            Dr. Nahas <InternalLink to="/practice-closure-notice">closed his outpatient clinical practice</InternalLink> at Agile Psychiatry, effective 1/31/23, and
                            therefore is not accepting new patients.
                        </TextParagraph>
                        <TextParagraph>
                            We recommend using the following options to find another psychiatrist in your area:
                        </TextParagraph>
                        <DivWithText className="my-4">
                            <OrderedList>
                                <li>If you have health insurance, you may be able to search for psychiatrists on your insurance providers website</li>
                                <li>You can search for psychiatrists on <ExternalLink to={"https://www.psychologytoday.com"}>PsychologyToday</ExternalLink></li>
                                <li>Ask your primary care provider for a referral</li>
                                <li>In case of emergencies, please do not hesitate to call 911 or go to your nearest ER</li>
                            </OrderedList>
                        </DivWithText>
                        {/*<TextParagraph>*/}
                        {/*    Follow the steps below to <span className="underline">request</span> an appointment at Agile*/}
                        {/*    Psychiatry. Note that after the request is submitted, Dr. Nahas will contact you to discuss*/}
                        {/*    your request and make sure that Agile Psychiatry is a good fit for your needs.*/}
                        {/*    An appointment <span className="underline">must be confirmed</span> with Dr. Nahas before*/}
                        {/*    it will take place.*/}
                        {/*</TextParagraph>*/}
                        {/*<DivWithText className="my-4">*/}
                        {/*    <OrderedList>*/}
                        {/*        <li>Note that office appointments and online appointments are available on different days of the week</li>*/}
                        {/*        <li>Select an In-Office or Video Initial Visit in the form below depending on your need</li>*/}
                        {/*        <li>Select a date and time that works for you</li>*/}
                        {/*        <li>Complete the pre-appointment questionnaire</li>*/}
                        {/*        <li>Dr. Nahas will contact you for a brief (~15-minute) phone call in the near future to discuss your*/}
                        {/*            request</li>*/}
                        {/*        <li>If your appointment is confirmed, sign up for the patient portal and complete*/}
                        {/*            the intake forms at least 24 hours before your first appointment</li>*/}
                        {/*    </OrderedList>*/}
                        {/*</DivWithText>*/}
                        {/*<DivWithText className="my-4 font-bold text-2xl">*/}
                        {/*    Dr. Nahas will be out of office from August 1st - August 14th and will be responding*/}
                        {/*    to appointment requests when he returns on August 15th.*/}
                        {/*</DivWithText>*/}
                        {/*<DivWithText className="my-4 font-bold">*/}
                        {/*    If you encounter any issues requesting an appointment please call 512-337-6684 during regular*/}
                        {/*    business hours or submit <InternalLink to="/feedback">feedback</InternalLink> regarding the*/}
                        {/*    problem so that we may assist you.*/}
                        {/*</DivWithText>*/}
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-dark"/>
            {/*<Section shade="light">*/}
            {/*    <SectionRow>*/}
            {/*    <iframe width="100%" height="1000"*/}
            {/*            src="https://ehr.charmtracker.com/publicCal.sas?method=getCal&digest=1d15d1c82ff5a7261f015d95295b6059f46e0f02983651ba049bb9a89a343a119c898e7ad26733530e4b197b50fc1f07debf17855dc5f624"*/}
            {/*            style={{overflow: "hidden"}} frameBorder="0"></iframe>*/}
            {/*    </SectionRow>*/}
            {/*</Section>*/}
            {/*<SVGCurve direction="right-slope" transition="light-dark"/>*/}
            <Section shade="dark">
                <SectionRow>
                    <TextDiv className="h-20"/>
                </SectionRow>
            </Section>
        </LayoutGeneral>
    );
}

export default AboutPage;
